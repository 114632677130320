import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Flex, Text, Grid, Box } from 'ui';
import { paths } from 'routes';
import { Divider } from 'components';
import { canNavigate } from 'utils/canNavigate';
import { useGetRole } from 'utils/useGetRole';
import { ROLES } from 'constants/roles';
import { useLocalStorage, useMe } from 'utils';
import { CreateReportModal } from 'pages/Reports/components/CreateReportModal/CreateReportModal';
import { useQueryUnreadMessages } from 'graphql/query/useQueryUnreadMessages';
import { SidebarButton } from './SidebarButton';
import { SidebarLink } from './SidebarLink';

type NavElement = {
  label: string;
  to?: string;
  onClick?: () => void;
  icon: string;
  allowedRoles?: ROLES[];
};

export const Sidebar = () => {
  const { data } = useQueryUnreadMessages({ pollInterval: 10 * 1000 });
  const unreadMessages = data?.messages.items.filter(
    (item) => !item.userRead,
  ).length;

  const NAV_ELEMENTS: NavElement[][] = [
    [
      {
        label: 'Home',
        to: paths.base,
        icon: '20-home',
      },
    ],
    [
      {
        to: paths.main.uploadOrder,
        label: 'Upload order',
        icon: '20-plus',
        allowedRoles: ['CLIENT'],
      },
      {
        to: paths.main.yourOrders,
        label: 'Your orders',
        icon: '20-orders',
        allowedRoles: ['CLIENT'],
      },
      {
        to: paths.main.users,
        label: 'Users',
        icon: '20-users',
        allowedRoles: ['ADMIN'],
      },
      {
        to: paths.main.cartAndDrafts,
        label: 'Cart & Drafts',
        icon: '20-cart',
        allowedRoles: ['CLIENT'],
      },
      {
        to: paths.main.itemStatus,
        label: 'Item status',
        icon: '20-checkOutline',
        allowedRoles: ['SALES', 'ADMIN'],
      },
      {
        to: paths.main.customerOrdersSales,
        label: 'Customer orders',
        icon: '20-customerOrders',
        allowedRoles: ['SALES'],
      },
      {
        to: paths.main.customerOrdersAdmin,
        label: 'Customer orders',
        icon: '20-customerOrders',
        allowedRoles: ['ADMIN'],
      },
    ],
    [
      {
        to: paths.outside.webpartsList,
        label: 'Webparts list',
        icon: '20-settings',
        allowedRoles: ['CLIENT', 'SALES'],
      },
      {
        to: paths.main.messages,
        label: `Messages ${
          unreadMessages
            ? `(${unreadMessages > 10 ? `+10` : unreadMessages})`
            : ''
        }`,
        icon: '20-email',
        allowedRoles: ['CLIENT', 'SALES'],
      },
      {
        to: paths.main.wishlist,
        label: 'Wishlist',
        icon: '20-table',
        allowedRoles: ['ADMIN'],
      },
      {
        to: paths.main.mailing,
        label: 'Mailing',
        icon: '20-email',
        allowedRoles: ['ADMIN'],
      },
      {
        to: paths.main.documents,
        label: 'Documents',
        icon: '20-documents',
        allowedRoles: ['SALES', 'CLIENT', 'ADMIN'],
      },
      {
        to: paths.outside.news,
        label: 'News',
        icon: '20-megaphone',
        allowedRoles: ['SALES', 'CLIENT'],
      },
      {
        onClick: () => setIsCreateReportModalOpen(true),
        label: 'Reports',
        icon: '20-chart',
        allowedRoles: ['ADMIN'],
      },
    ],
  ];

  const ANCHORS = [
    {
      href: 'https://jukieurope.com/en/ism/terms',
      label: 'Terms of Use',
    },
    { href: 'https://jukieurope.com/en/ism/privacy', label: 'Privacy Policy' },
    { to: paths.main.faq, label: 'FAQ' },
  ];

  const [HOME, ORDER, OTHERS] = NAV_ELEMENTS;

  const role = useGetRole();
  const me = useMe();
  const [isOpen, setIsOpen] = useLocalStorage<boolean>('openMenu', true);
  const [isCreateReportModalOpen, setIsCreateReportModalOpen] =
    useLocalStorage<boolean>('openCreateReportModal', false);
  const toggle = () => setIsOpen((prev) => !prev);

  const canNavigateOthers = (element?: NavElement) => {
    if (element?.to === paths.main.documents && role?.includes('CLIENT')) {
      return me?.downloadFilesPermission;
    }
    if (element?.to === paths.main.cartAndDrafts) {
      return (
        me?.createOrdersPermission && canNavigate(role, element?.allowedRoles)
      );
    }
    if (element?.to === paths.main.uploadOrder) {
      return (
        me?.createOrdersPermission && canNavigate(role, element?.allowedRoles)
      );
    }
    return canNavigate(role, element?.allowedRoles);
  };

  return (
    <>
      <CreateReportModal
        open={isCreateReportModalOpen}
        handleClose={() => setIsCreateReportModalOpen(false)}
      />
      <SidebarWrapper
        isOpen={isOpen}
        flexDirection="column"
        justifyContent="space-between"
        pb="s20"
      >
        <Flex flexDirection="column">
          <SidebarLinkGroup>
            {HOME.map((element) => (
              <SidebarLink {...element} isOpen={isOpen} key={element.label} />
            ))}
          </SidebarLinkGroup>
          <Divider my="s05" ml="s30" />
          <SidebarLinkGroup>
            {ORDER.map(
              (element) =>
                canNavigateOthers(element) && (
                  <SidebarLink
                    {...element}
                    isOpen={isOpen}
                    key={element.label}
                  />
                ),
            )}
          </SidebarLinkGroup>
          <Divider my="s05" ml="s30" />
          <SidebarLinkGroup>
            {OTHERS?.map(
              (element) =>
                canNavigateOthers(element) && (
                  <SidebarLink
                    {...element}
                    isOpen={isOpen}
                    key={element.label}
                  />
                ),
            )}
          </SidebarLinkGroup>
        </Flex>
        <Flex flexDirection="column">
          {isOpen && (
            <>
              <Grid gridGap="s15" mb="s30">
                {ANCHORS.map(({ label, href, to }) => (
                  <Anchor
                    href={href}
                    to={to}
                    as={to ? Link : Box}
                    forwardedAs="a"
                    key={label}
                  >
                    {label}
                  </Anchor>
                ))}
              </Grid>
              <Copyright>® Copyright 2021</Copyright>
              <Copyright>JUKI CENTRAL EUROPE</Copyright>
            </>
          )}
          <Divider mt="s30" mb="s20" ml="s30" />
          <SidebarButton isOpen={isOpen} onClick={toggle} label="Close menu" />
        </Flex>
      </SidebarWrapper>
    </>
  );
};

export const SidebarWrapper = styled(Flex)<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? '236px' : '68px')};
  transition: all 0.3s ease-out;
  will-change: width;
  overflow: auto;
  border-right: ${({ theme: { borders } }) => borders.thinGray10};
  max-height: calc(100vh - 78px);
  scrollbar-width: thin;
  ${({ theme: { colors } }) => css`
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${colors.gray0};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.gray10};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.gray40};
    }
  `};
  position: sticky;
  top: 78px;
`;

export const SidebarLinkGroup = styled(Grid).attrs({
  py: 's15',
})``;

export const Anchor = styled(Box)<{
  href?: string;
}>`
  ${({ theme: { colors, lineHeights, space } }) => css`
    color: ${colors.gray80};
    line-height: ${lineHeights.lh100} '';
    padding-left: ${space.s30};
  `};
  opacity: 0.72;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

export const Copyright = styled(Text).attrs({
  color: 'gray80',
  fontSize: 'fs100',
  lineHeight: 'lh100',
  pl: 's30',
})`
  opacity: 0.48;
  white-space: nowrap;
`;

import { FC } from 'react';
import { Text, Box, Flex, MarginProps, PaddingProps } from 'ui';
import { Table, TableBody, Heading, Button } from 'components';
import { OrderItem } from 'graphql/types/OrderItem';
import { RecentOrdersRow } from './RecentOrdersRow';
import { RecentOrdersHead } from './RecentOrdersHead';

type RecentOrdersProps = {
  data: OrderItem[];
  onViewOrder: (id: number[]) => void;
} & MarginProps &
  PaddingProps;

const PRICE_LIST_URL = `${process.env.REACT_APP_REST_API_HOST}/juki/download/priceList`;

export const RecentOrders: FC<RecentOrdersProps> = ({
  data,
  onViewOrder,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Heading>Recent orders</Heading>
      <Box m="s40" mb="s50">
        <Table>
          <RecentOrdersHead />
          <TableBody>
            {data.map((order) => (
              <RecentOrdersRow
                onViewOrder={onViewOrder}
                key={order.id}
                {...order}
              />
            ))}
          </TableBody>
        </Table>
        <Flex mt="s20">
          <Text>You can download the price list by clicking</Text>
          <a href={PRICE_LIST_URL} download>
            <Button
              ml="s15"
              icon={{ position: 'left', color: 'blue', name: '20-download' }}
              variant="text"
            >
              here
            </Button>
          </a>
        </Flex>
      </Box>
    </Box>
  );
};

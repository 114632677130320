import { Flex, Icon, Text } from 'ui';

type OrderStatusProps = { status: string; showLabel?: boolean };

export const ORDER_STATUSES = [
  {
    icon: '12-create',
    status: 'CREATED',
    label: 'Created',
    color: 'blue',
  },
  {
    icon: '12-arrow',
    status: 'PLACED',
    label: 'Placed',
    color: 'gray80',
  },
  {
    icon: '12-check',
    status: 'CONFIRMED',
    label: 'Confirmed',
    color: 'success50',
  },
  {
    icon: '24-picking',
    status: 'PICKING',
    label: 'Picking',
    color: 'blue',
  },
  {
    icon: '12-packing',
    status: 'PACKING',
    label: 'Packed',
    color: 'blue',
  },
  {
    icon: '12-shipped',
    status: 'SHIPPED',
    label: 'Shipped',
    color: 'blue',
  },
  {
    icon: '12-document',
    status: 'INVOICED',
    label: 'Invoiced',
    color: 'blue',
  },
  {
    icon: '12-close',
    status: 'CANCELED',
    label: 'Canceled',
    color: 'alert50',
  },
] as const;

export const OrderStatus = ({ status, showLabel }: OrderStatusProps) => {
  const statusInfo = ORDER_STATUSES.find(
    (s) => s.status === status.toUpperCase(),
  );

  return (
    <Flex alignItems="center">
      <Icon
        color={statusInfo?.color ?? 'blue'}
        name={statusInfo?.icon ?? '12-arrow'}
      />
      {showLabel && (
        <Text
          color="gray80"
          fontSize="fs200"
          lineHeight="lh300"
          fontWeight="medium"
          ml="s10"
        >
          {statusInfo?.label}
        </Text>
      )}
    </Flex>
  );
};

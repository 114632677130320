import { Flex, Icon, Text } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  Button,
  ToggleSwitch,
  ReplacementStatus,
} from 'components';
import { CartItem } from 'graphql/types/CartItem';
import { Fragment } from 'react';
import { tableCellNames } from './constants/tableCellNames';

type ReplacamentChoiceWithSetProps = {
  data: CartItem[];
  onDelete: OnDelete;
  onReplaceCartItem: OnReplaceCartItem;
  deletedItemsIds: number[];
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
};

type OnDelete = (id: number, section: string) => void;

type OnReplaceCartItem = (
  cartItemId: number,
  commodities: number[],
  quantity: number,
  section: string,
) => void;

export const ReplacamentChoiceWithSet = ({
  data,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
  deletedItemsIds,
}: ReplacamentChoiceWithSetProps) => {
  return (
    <div>
      <Table>
        <Head />
        <TableBody>
          {data?.map((item) => (
            <RowSet
              onMonitorItem={onMonitorItem}
              onReplaceCartItem={onReplaceCartItem}
              key={`ReplacamentChoiceWithSet-${item.id}`}
              onDelete={onDelete}
              deletedItemsIds={deletedItemsIds}
              item={item}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

type RowSetProps = {
  item: CartItem;
  onDelete: OnDelete;
  onReplaceCartItem: OnReplaceCartItem;
  deletedItemsIds: number[];
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
};

const RowSet = ({
  item,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
  deletedItemsIds,
}: RowSetProps) => {
  return (
    <Row disabledBorder isDeleted={deletedItemsIds.includes(item.id)}>
      <td colSpan={11}>
        <Table>
          <TableBody>
            <RowBasic
              onMonitorItem={onMonitorItem}
              onReplaceCartItem={onReplaceCartItem}
              item={item}
              onDelete={onDelete}
            />
            {item.commodity.replacements?.map((replacement, index) => {
              const nextChild = item.commodity.replacements?.[index + 1];

              const isSetNextItem =
                nextChild?.setNumber === replacement?.setNumber;

              return (
                <Fragment key={replacement.id}>
                  {replacement.items.map((replacementItem, itemIndex) =>
                    itemIndex === 0 && index === 0 ? null : (
                      <Row key={replacementItem.id} disabledBorder>
                        <Cell />
                        <Cell />
                        <Cell />
                        <Cell divider />
                        <Cell border={!isSetNextItem} />
                        <Cell border={!isSetNextItem}>
                          {replacementItem.replacement.remarks}
                        </Cell>
                        <Cell border={!isSetNextItem}>
                          {replacementItem.commodity.itemCode}
                        </Cell>
                        <Cell border={!isSetNextItem}>
                          {replacementItem.quantity}
                        </Cell>
                        <Cell border={!isSetNextItem}>
                          {replacementItem.commodity.price}
                        </Cell>
                        <Cell border={!isSetNextItem} divider width="60px">
                          {itemIndex === 0 && (
                            <Button
                              onClick={() =>
                                onReplaceCartItem(
                                  item.id,
                                  replacement.items.map(
                                    ({ commodity }) => commodity.id,
                                  ),
                                  replacementItem.quantity,
                                  'ReplacementAvailableChoiceWithSet',
                                )
                              }
                              variant="outline-primary"
                              size="small"
                              icon={{
                                name: '16-check',
                                color: 'blue',
                              }}
                            />
                          )}
                        </Cell>
                      </Row>
                    ),
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </td>
    </Row>
  );
};

type RowBasicProps = {
  item: CartItem;
  onDelete: OnDelete;
  onReplaceCartItem: OnReplaceCartItem;
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
};

const RowBasic = ({
  item,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
}: RowBasicProps) => (
  <Row>
    <Cell>{item.commodity.itemCode}</Cell>
    <Cell>{item.commodity.description}</Cell>
    <Cell>{item.commodity.price}</Cell>
    <Cell divider width="70px">
      <Flex justifyContent="space-between">
        <Text>{item.quantity}</Text>
        <Icon mr="s10" color="gray80" name="20-arrow-right" />
      </Flex>
    </Cell>
    <Cell width="120px">
      <ReplacementStatus status="success" uppercase>
        Choice
      </ReplacementStatus>
    </Cell>
    <Cell>{item.commodity.replacements[0]?.items[0].replacement.remarks}</Cell>
    <Cell>{item.commodity.replacements[0]?.items[0].commodity.itemCode}</Cell>
    <Cell width="70px">
      {item.commodity.replacements[0]?.items[0].quantity}
    </Cell>
    <Cell width="70px">
      {item.commodity.replacements[0]?.items[0].commodity.price}
    </Cell>
    <Cell divider width="60px">
      <Button
        onClick={() =>
          onReplaceCartItem(
            item.id,
            item.commodity.replacements[0]?.items.map(
              ({ commodity }) => commodity.id,
            ),
            item.commodity.replacements[0]?.items[0].quantity,
            'ReplacementAvailableChoiceWithSet',
          )
        }
        variant="outline-primary"
        size="small"
        icon={{
          name: '16-check',
          color: 'blue',
        }}
      />
    </Cell>
    <Cell width="80px">
      <Flex justifyContent="space-between">
        <ToggleSwitch
          onChange={() =>
            onMonitorItem(
              item.id,
              item.monitored,
              'ReplacementAvailableChoiceWithSet',
            )
          }
          defaultChecked={item.monitored}
        />
        <Button
          mr="s10"
          variant="icon"
          onClick={() => onDelete(item.id, 'ReplacementAvailableChoiceWithSet')}
          icon={{ name: '16-delete', color: 'gray05' }}
        />
      </Flex>
    </Cell>
  </Row>
);

const Head = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell>{tableCellNames.itemCode}</TableHeadCell>
      <TableHeadCell>{tableCellNames.description}</TableHeadCell>
      <TableHeadCell>{tableCellNames.price}</TableHeadCell>
      <TableHeadCell divider width="70px">
        {tableCellNames.qty}
      </TableHeadCell>
      <TableHeadCell width="120px">
        {tableCellNames.replacementStatus}
      </TableHeadCell>
      <TableHeadCell>{tableCellNames.remarks}</TableHeadCell>
      <TableHeadCell>{tableCellNames.replacementBy}</TableHeadCell>
      <TableHeadCell width="70px">{tableCellNames.qty}</TableHeadCell>
      <TableHeadCell width="70px">{tableCellNames.price}</TableHeadCell>
      <TableHeadCell width="60px" divider>
        {tableCellNames.acceptReplace}
      </TableHeadCell>
      <TableHeadCell width="80px">{tableCellNames.monitorStatus}</TableHeadCell>
    </TableHeadRow>
  </TableHead>
);

import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Box, Flex, Icon, Text } from 'ui';

export type SidebarLinkProps = {
  icon: string;
  isOpen: boolean;
  label: string;
  to?: string;
  onClick?: () => void;
};

export const SidebarLink = ({
  icon,
  isOpen,
  label,
  to,
  onClick,
}: SidebarLinkProps) => {
  const { pathname } = useLocation();

  const checkActive = () => {
    if (!pathname) return 0;
    return pathname === to ? 1 : 0;
  };
  const isOutsideLink = to?.match('http') !== null;
  const active = checkActive();
  return to ? (
    <LinkStyle
      to={to}
      href={to}
      active={active}
      forwardedAs={isOutsideLink ? 'a' : Link}
      target={isOutsideLink ? '_blank' : '_self'}
    >
      <Flex pl="s30" py="s15" alignItems="center">
        <Icon my="s025" color="blue" name={icon} />
        {isOpen && (
          <SidebarLinkLabel
            color="blue"
            fontSize="fs300"
            fontWeight="medium"
            isOpen={isOpen}
            lineHeight="lh300"
            ml="s10"
          >
            {label}
          </SidebarLinkLabel>
        )}
      </Flex>
    </LinkStyle>
  ) : (
    <LinkStyle onClick={onClick}>
      <Flex pl="s30" py="s15" alignItems="center">
        <Icon my="s025" color="blue" name={icon} />
        {isOpen && (
          <SidebarLinkLabel
            color="blue"
            fontSize="fs300"
            fontWeight="medium"
            isOpen={isOpen}
            lineHeight="lh300"
            ml="s10"
          >
            {label}
          </SidebarLinkLabel>
        )}
      </Flex>
    </LinkStyle>
  );
};

export const LinkStyle = styled(Box)<{ active?: number }>`
  cursor: pointer;
  ${({ theme, active }) => css`
    &:hover {
      svg,
      span {
        color: ${theme.colors.gray80};
      }
    }
    ${active &&
    css`
      svg,
      span {
        color: ${theme.colors.gray80};
      }
    `}
  `}
`;

export const SidebarLinkLabel = styled(Text)<{ isOpen: boolean }>`
  white-space: nowrap;
`;

import { useState } from 'react';
import { Flex, Grid } from 'ui';
import { Breadcrumbs, Panel, AddProduct, Upload } from 'components';
import { paths } from 'routes';
import {
  useMutationAddToCart,
  useMutationCartOrderAll,
  useMutationImportCsvToCart,
  useMutationRemoveFromCart,
  useMutationClearCart,
} from 'graphql/mutation';
import { SingleValue } from 'react-select';
import {
  useQueryCart,
  useQuerySearchCommodities,
  GET_CART,
} from 'graphql/query';
import { Commodity } from 'graphql/types/Commodity';
import { useNavigate } from 'react-router-dom';
import { useCreateOrdersPermission } from 'pages/CartAndDrafts/utils/useCreateOrdersPremission';
import { notify } from 'utils';
import { CartItem } from 'graphql/types/CartItem';
import { OrderPreview } from './components';
import { OrderStatusModal } from './components/OrderStatusModal/OrderStatusModal';

const refetchQueries = [
  { query: GET_CART, variables: { wishlist: false } },
  { query: GET_CART, variables: { wishlist: true } },
];

const checkIsValidationNeeded = (items: CartItem[]) => true;

const getWishlistData = (items: CartItem[]) =>
  items.filter((item) => {
    return item.wishlist;
  });

export const UploadOrder = () => {
  const navigate = useNavigate();
  useCreateOrdersPermission();
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const { data, refetch } = useQueryCart({
    variables: { wishlist: false, section: 'Cart' },
  });

  const [importCsvToCart, { loading: uploadLoading }] =
    useMutationImportCsvToCart();

  const handleUploadedFileChange = (file: File[]) => {
    importCsvToCart({
      variables: { file: file[0] },
      onCompleted: ({ importCsvToCart: { missingItems } }) => {
        refetch();
        if (missingItems?.length === 0) {
          notify('Items added.', { type: 'success' });
        }
      },
      onError: () => {
        notify('Cannot import file.', { type: 'error' });
      },
    });
  };

  const [addToCart] = useMutationAddToCart({ refetchQueries });
  const [removeFromCart] = useMutationRemoveFromCart({ refetchQueries });
  const [cartOrderAll] = useMutationCartOrderAll({ refetchQueries });
  const [searchCommodities] = useQuerySearchCommodities();
  const [clearCart] = useMutationClearCart({ refetchQueries });

  const handleDelete = (id: number) => {
    removeFromCart({ variables: { id, section: 'Cart' }, refetchQueries });
  };

  const handleSubmit = (
    autocompleteData: SingleValue<Commodity> | undefined,
  ) => {
    if (autocompleteData?.id)
      addToCart({
        variables: { id: autocompleteData?.id, quantity: 1 },
        onCompleted: () => {
          refetch();
          notify('Item added.', { type: 'success' });
        },
        onError: () => {
          notify('something goes wrong.', { type: 'error' });
        },
      });
  };

  const handleLoad = async (value: string) => {
    const options = await searchCommodities({
      variables: { phrase: value },
    });

    return options.data?.searchCommodities ?? [];
  };

  const handleProceedOrder = () => {
    if (checkIsValidationNeeded(data?.cart.items ?? [])) {
      navigate(paths.main.validation);
      return;
    }
    cartOrderAll({
      onCompleted: () => {
        setIsStatusModalOpen(true);
      },
      onError: () => {
        notify('Something went wrong. Please contact the administrator', {
          type: 'error',
        });
      },
    });
  };

  const handleClearCart = () => {
    clearCart({
      onCompleted: () => {
        refetch();
      },
    });
  };

  const cartItems = data
    ? [...data.cart.items].filter((item) => item.wishlist === false).reverse()
    : [];

  return (
    <Grid gridTemplateRows={'40px 1fr'}>
      <Breadcrumbs
        px="s30"
        items={[
          { label: 'Home', to: paths.base },
          { label: 'Upload order', to: paths.main.uploadOrder },
        ]}
      />
      <Flex flexDirection="column">
        <Panel label={'Upload Order'}>
          <Upload onChange={handleUploadedFileChange} loading={uploadLoading} />
        </Panel>
        <Panel label={'Add Single Product'}>
          <AddProduct mb="s40" onLoad={handleLoad} onSubmit={handleSubmit} />
        </Panel>
        <Panel label={'Your Order'}>
          <OrderPreview
            onProceedOrder={handleProceedOrder}
            onDelete={handleDelete}
            onClearCart={handleClearCart}
            data={cartItems}
          />
        </Panel>
      </Flex>
      <OrderStatusModal
        wishlistData={getWishlistData(data?.cart.items ?? [])}
        open={isStatusModalOpen}
        handleClose={() => setIsStatusModalOpen(false)}
      />
    </Grid>
  );
};

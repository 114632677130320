import { useState, useEffect } from 'react';
import { Button, ToggleSwitch } from 'components';
import { Flex, Text } from 'ui';

import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { CartItem } from 'graphql/types/CartItem';
import { OrdersRow } from './OrdersRow';
import { ReferenceNumber } from './ReferenceNumber';

type CartsAndDraftsListProps = {
  tableData?: CartItem[];
  reference?: string;
  onDelete: (id: number) => void;
  onIncrement: (id: number, quantity: number) => void;
  onDecrement: (id: number, quantity: number) => void;
  onMonitorItem: (id: number, currentValue: boolean) => void;
  onMonitorAll: (currentValue: boolean) => void;
  onUpdateCartReference: (reference: string) => void;
  onOrderAll: (reference: string) => void;
  onClearCart: () => void;
  monitoredAll: boolean;
  loading: boolean;
  recentlyAddedIds: number[];
  deletedItemsIds: number[];
};

export const CartsAndDraftsList = ({
  tableData,
  reference,
  onDelete,
  onIncrement,
  onDecrement,
  onOrderAll,
  onMonitorItem,
  onMonitorAll,
  monitoredAll,
  loading,
  recentlyAddedIds,
  deletedItemsIds,
  onClearCart,
}: CartsAndDraftsListProps) => {
  const [orderEnabled, setOrderEnabled] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState(reference || '');

  useEffect(() => {
    const enable = tableData?.find((item) => item.commodity.sellable);
    setOrderEnabled(!enable);
  }, [tableData]);

  return (
    <Flex pt="s10" flexDirection="column" flex="1">
      <Flex
        mb="s50"
        mr="s20"
        alignItems="flex-end"
        flexDirection="column"
        flex="1"
      >
        <Button
          onClick={onClearCart}
          disabled={!tableData?.length}
          variant="text"
          ml="s30"
        >
          Clear all
        </Button>
      </Flex>

      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell flex={104}>Item code</TableHeadCell>
            <TableHeadCell flex={168}>Description</TableHeadCell>
            <TableHeadCell flex={240}>Quantity</TableHeadCell>
            <TableHeadCell flex={110}>List price</TableHeadCell>
            <TableHeadCell flex={364}>Stock status</TableHeadCell>
            <TableHeadCell flex={112}>Monitor status</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {tableData?.map((data) => (
            <OrdersRow
              onMonitorItem={onMonitorItem}
              onIncrement={onIncrement}
              onDecrement={onDecrement}
              onDelete={onDelete}
              key={data.id}
              loading={loading}
              recentlyAddedIds={recentlyAddedIds}
              deletedItemsIds={deletedItemsIds}
              {...data}
            />
          ))}
        </TableBody>
      </Table>
      <Flex mt="s20" mb="s40" justifyContent="flex-end">
        <Flex alignItems="center">
          <Text mr="s10" lineHeight="lh100">
            Monitor all
          </Text>
          <ToggleSwitch
            onChange={() => onMonitorAll(monitoredAll)}
            checked={monitoredAll}
          />
          <ReferenceNumber onChange={setReferenceNumber} />
          <Flex ml="s30">
            <Button
              variant="primary"
              onClick={() => onOrderAll(referenceNumber)}
              disabled={orderEnabled}
            >
              Order all items
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

import { Flex, Icon, Text } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  Button,
  ToggleSwitch,
  ReplacementStatus,
} from 'components';
import { CartItem } from 'graphql/types/CartItem';
import { tableCellNames } from './constants/tableCellNames';
import { ReplaceCartItem } from '../../../types';

type ReplacamentBasicProps = {
  data: CartItem[];
  onDelete: (id: number, section: string) => void;
  onReplaceCartItem: OnReplaceCartItem;
  onReplaceAllCartItems: (items: ReplaceCartItem[], section: string) => void;
  deletedItemsIds: number[];
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
  onMonitorAll: (currentValue: boolean, id: number[], section: string) => void;
};

type OnReplaceCartItem = (
  cartItemId: number,
  commodities: number[],
  quantity: number,
  section: string,
) => void;

export const ReplacamentBasic = ({
  data,
  onDelete,
  onReplaceCartItem,
  onReplaceAllCartItems,
  onMonitorItem,
  deletedItemsIds,
  onMonitorAll,
}: ReplacamentBasicProps) => {
  const hasMonitoredAllItems = !data
    ?.map((item) => item.monitored)
    .includes(false);

  return (
    <Flex flexDirection="column">
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>{tableCellNames.itemCode}</TableHeadCell>
            <TableHeadCell>{tableCellNames.description}</TableHeadCell>
            <TableHeadCell>{tableCellNames.price}</TableHeadCell>
            <TableHeadCell divider width="70px">
              {tableCellNames.qty}
            </TableHeadCell>
            <TableHeadCell width="120px">
              {tableCellNames.replacementStatus}
            </TableHeadCell>
            <TableHeadCell>{tableCellNames.replacementBy}</TableHeadCell>
            <TableHeadCell width="70px">{tableCellNames.qty}</TableHeadCell>
            <TableHeadCell divider width="70px">
              {tableCellNames.price}
            </TableHeadCell>
            <TableHeadCell width="250px">
              {tableCellNames.monitorStatus}
            </TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <Row
              isDeleted={deletedItemsIds.includes(item.id)}
              key={`ReplacamentBasic-${item.id}`}
            >
              <Cell>{item.commodity.itemCode}</Cell>
              <Cell>{item.commodity.description}</Cell>
              <Cell>{item.commodity.price}</Cell>
              <Cell divider>
                <Flex justifyContent="space-between">
                  <Text>{item.quantity}</Text>
                  <Icon mr="s10" color="gray80" name="20-arrow-right" />
                </Flex>
              </Cell>
              <Cell>
                <ReplacementStatus status="success">1 to 1</ReplacementStatus>
              </Cell>
              <Cell>
                {item.commodity.replacements[0].items[0].commodity.itemCode}
              </Cell>
              <Cell>{item.quantity}</Cell>
              <Cell divider>
                {item.commodity.replacements[0].items[0].commodity.price}
              </Cell>
              <Cell>
                <Flex alignItems="center" justifyContent="space-between">
                  <ToggleSwitch
                    onChange={() =>
                      onMonitorItem(
                        item.id,
                        item.monitored,
                        'ReplacementAvailable',
                      )
                    }
                    checked={item.monitored}
                  />
                  <Button
                    variant="icon"
                    onClick={() => onDelete(item.id, 'ReplacementAvailable')}
                    icon={{ name: '16-delete', color: 'gray05' }}
                  />
                  <Button
                    onClick={() =>
                      onReplaceCartItem(
                        item.id,
                        [item.commodity.replacements[0].items[0].commodity.id],
                        item.commodity.replacements[0].items[0].quantity,
                        'ReplacementAvailable',
                      )
                    }
                    size="small"
                    variant="outline-primary"
                  >
                    Accept
                  </Button>
                </Flex>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
      <Flex
        mt="s20"
        alignItems="center"
        alignSelf="flex-end"
        justifyContent="flex-end"
      >
        <Text mr="s10" lineHeight="lh100">
          Monitor all
        </Text>
        <ToggleSwitch
          onChange={() =>
            onMonitorAll(hasMonitoredAllItems, [], 'ReplacementAvailable')
          }
          checked={hasMonitoredAllItems}
        />
        <Button
          onClick={() =>
            onReplaceAllCartItems(
              data?.map((item) => ({
                cartItemId: item.id,
                commodities: [
                  item.commodity.replacements[0].items[0].commodity.id,
                ],
                quantity: item.commodity.replacements[0].items[0].quantity,
              })),
              'ReplacementAvailable',
            )
          }
          ml="s30"
          variant="primary"
        >
          Accept all
        </Button>
      </Flex>
    </Flex>
  );
};

import { Fragment } from 'react';
import { Flex, Icon, Text } from 'ui';
import {
  Cell,
  Button,
  ToggleSwitch,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  ReplacementStatus,
} from 'components';
import { CartItem } from 'graphql/types/CartItem';
import { ReplaceCartItem } from 'pages/validation/types';
import { tableCellNames } from './constants/tableCellNames';

type ReplacamentSetProps = {
  data: CartItem[];
  onDelete: OnDelete;
  onReplaceCartItem: OnReplaceCartItem;
  deletedItemsIds: number[];
  onReplaceAllCartItems: (items: ReplaceCartItem[], section: string) => void;
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
  onMonitorAll: (currentValue: boolean, id: number[], section: string) => void;
};

type OnDelete = (id: number, section: string) => void;

type OnReplaceCartItem = (
  cartItemId: number,
  commodities: number[],
  quantity: number,
  section: string,
) => void;

export const ReplacamentSet = ({
  data,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
  deletedItemsIds,
  onReplaceAllCartItems,
  onMonitorAll,
}: ReplacamentSetProps) => {
  const hasMonitoredAllItems = !data
    ?.map((item) => item.monitored)
    .includes(false);
  return (
    <div>
      <Table>
        <Head />
        <TableBody>
          {data?.map((item) => (
            <Fragment key={`ReplacamentSet-${item.id}`}>
              <RowSet
                onMonitorItem={onMonitorItem}
                onReplaceCartItem={onReplaceCartItem}
                onDelete={onDelete}
                deletedItemsIds={deletedItemsIds}
                item={item}
              />
            </Fragment>
          ))}
        </TableBody>
      </Table>
      <Flex
        mt="s20"
        alignItems="center"
        alignSelf="flex-end"
        justifyContent="flex-end"
      >
        <Text mr="s10" lineHeight="lh100">
          Monitor all
        </Text>
        <ToggleSwitch
          onChange={() =>
            onMonitorAll(hasMonitoredAllItems, [], 'ReplacementAvailableSet')
          }
          checked={hasMonitoredAllItems}
        />
        <Button
          onClick={() =>
            onReplaceAllCartItems(
              data?.map((item) => ({
                cartItemId: item.id,
                commodities: item.commodity.replacements[0].items.map(
                  ({ commodity }) => commodity.id,
                ),
                quantity: item.commodity.replacements[0].items[0].quantity,
              })),
              'ReplacementAvailableSet',
            )
          }
          ml="s30"
          variant="primary"
        >
          Accept all
        </Button>
      </Flex>
    </div>
  );
};

type RowSetProps = {
  item: CartItem;
  onDelete: OnDelete;
  onReplaceCartItem: OnReplaceCartItem;
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
  deletedItemsIds: number[];
};

const RowSet = ({
  item,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
  deletedItemsIds,
}: RowSetProps) => {
  const { commodity } = item;

  return (
    <Row disabledBorder isDeleted={deletedItemsIds.includes(item.id)}>
      <td colSpan={10}>
        <Table>
          <TableBody>
            <RowBasic
              onMonitorItem={onMonitorItem}
              onReplaceCartItem={onReplaceCartItem}
              onDelete={onDelete}
              item={item}
            />
            {commodity.replacements[0]?.items?.map(
              (replacement, index) =>
                index !== 0 && (
                  <Row key={`ReplacamentSet-${replacement.id}`} disabledBorder>
                    <Cell />
                    <Cell />
                    <Cell />
                    <Cell divider />
                    <Cell border />
                    <Cell border>{replacement.replacement.remarks}</Cell>
                    <Cell border>{replacement.commodity.itemCode}</Cell>
                    <Cell border>{replacement.quantity}</Cell>
                    <Cell border divider>
                      {replacement.commodity.price}
                    </Cell>
                  </Row>
                ),
            )}
          </TableBody>
        </Table>
      </td>
    </Row>
  );
};

type RowBasicProps = {
  item: CartItem;
  onDelete: OnDelete;
  onReplaceCartItem: OnReplaceCartItem;
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
};

const RowBasic = ({
  item,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
}: RowBasicProps) => (
  <Row>
    <Cell>{item.commodity.itemCode}</Cell>
    <Cell>{item.commodity.description}</Cell>
    <Cell>{item.commodity.price}</Cell>
    <Cell divider width="70px">
      <Flex justifyContent="space-between">
        <Text>{item.quantity}</Text>
        <Icon mr="s10" color="gray80" name="20-arrow-right" />
      </Flex>
    </Cell>
    <Cell width="120px">
      <ReplacementStatus status="success" uppercase>
        Set
      </ReplacementStatus>
    </Cell>
    <Cell>{item.commodity.replacements[0]?.items[0].replacement.remarks}</Cell>
    <Cell>{item.commodity.replacements[0]?.items[0].commodity.itemCode}</Cell>
    <Cell width="70px">
      {item.commodity.replacements[0]?.items[0].quantity}
    </Cell>
    <Cell divider width="70px">
      {item.commodity.replacements[0]?.items[0].commodity.price}
    </Cell>
    <Cell width="250px">
      <Flex alignItems="center" justifyContent="space-between">
        <ToggleSwitch
          onChange={() =>
            onMonitorItem(item.id, item.monitored, 'ReplacementAvailableSet')
          }
          checked={item.monitored}
        />
        <Button
          variant="icon"
          onClick={() => onDelete(item.id, 'ReplacementAvailableSet')}
          icon={{ name: '16-delete', color: 'gray05' }}
        />
        <Button
          onClick={() =>
            onReplaceCartItem(
              item.id,
              item.commodity.replacements[0]?.items.map(
                ({ commodity }) => commodity.id,
              ),
              item.commodity.replacements[0]?.items[0].quantity,
              'ReplacementAvailableSet',
            )
          }
          size="small"
          variant="outline-primary"
        >
          Accept
        </Button>
      </Flex>
    </Cell>
  </Row>
);

const Head = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell>{tableCellNames.itemCode}</TableHeadCell>
      <TableHeadCell>{tableCellNames.description}</TableHeadCell>
      <TableHeadCell>{tableCellNames.price}</TableHeadCell>
      <TableHeadCell divider width="70px">
        {tableCellNames.qty}
      </TableHeadCell>
      <TableHeadCell width="120px">
        {tableCellNames.replacementStatus}
      </TableHeadCell>
      <TableHeadCell>{tableCellNames.remarks}</TableHeadCell>
      <TableHeadCell>{tableCellNames.replacementBy}</TableHeadCell>
      <TableHeadCell width="70px">{tableCellNames.qty}</TableHeadCell>
      <TableHeadCell divider width="70px">
        {tableCellNames.price}
      </TableHeadCell>
      <TableHeadCell width="250px">
        {tableCellNames.monitorStatus}
      </TableHeadCell>
    </TableHeadRow>
  </TableHead>
);

import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { CartItem } from 'graphql/types/CartItem';

export const useQueryReplacementAvailableSet = (
  options?: QueryHookOptions<
    ReplacementAvailableSetData,
    ReplacementAvailableSetVariables
  >,
) =>
  useQuery<ReplacementAvailableSetData, ReplacementAvailableSetVariables>(
    REPLACEMENT_AVAILABLE_SET,
    options,
  );

export const REPLACEMENT_AVAILABLE_SET = gql`
  query replacementAvailableSet($section: String) {
    replacementAvailableSet {
      id
      quantity
      monitored(section: $section)
      commodity {
        itemCode
        price
        description
        stockStatus
        stocks {
          stock
          source
          id
          location
          status
        }
        replacements {
          setNumber
          quantity
          commodity {
            itemCode
            id
            price
            description
          }
          items {
            id
            quantity
            commodity {
              itemCode
              id
              price
              description
            }
            replacement {
              remarks
            }
          }
          id
          createdAt
          updatedAt
        }
        validate {
          setNumber
          quantity
          commodity
          items {
            id
            commodity {
              itemCode
              id
              description
            }
          }

          id
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export type ReplacementAvailableSetVariables = {
  section: string;
};

export type ReplacementAvailableSetData = {
  replacementAvailableSet: CartItem[];
};

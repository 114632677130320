export const tableCellNames = {
  itemCode: 'Item code',
  description: 'Description',
  qty: 'Qty',
  replacementStatus: `Replacement status`,
  replacementBy: 'Replacement by',
  monitorStatus: 'Monitor status',
  remarks: 'Remarks',
  price: 'List price',
  acceptReplace: 'Accept replace',
};

import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export function useQueryUnreadMessages(
  options?: QueryHookOptions<UnreadMessagesData, UnreadMessagesVariables>,
) {
  const data = useQuery<UnreadMessagesData, UnreadMessagesVariables>(
    UNREAD_MESSAGES,
    options,
  );
  return data;
}

export const UNREAD_MESSAGES = gql`
  query messages {
    messages(
      criteria: { orderDir: "asc", orderBy: "id", offset: 0, limit: 11 }
    ) {
      items(onlyUnread: true) {
        id
        userRead
      }
      count
    }
  }
`;

export type UnreadMessagesVariables = {};

export interface MessageItem {
  userRead: boolean;
}

export interface UnreadMessagesData {
  messages: {
    count: number;
    items: MessageItem[];
  };
}
